import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'
import {
  Question,
  Answer,
  ImageContainer,
  QuestionRow,
  AnswerFirstRow,
  Border,
  ALink,
} from '../FAQs/styles'
import { IS_FOREIGN_COUNTRY } from '../../utils/constants'

const FAQItems = ({ question, answer, text, link, punctuation, page, placement }) => {
  const [showAnswer, setShowAnswer] = useState(false)
  const { plusImg, minusImg } = useStaticQuery(graphql`
    query FaqItemsQuery {
      plusImg: file(relativePath: { eq: "icons/plus-icon.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 24, height: 24, layout: FIXED, placeholder: NONE)
        }
      }
      minusImg: file(relativePath: { eq: "icons/minus-icon.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 24, height: 24, layout: FIXED, placeholder: NONE)
        }
      }
    }
  `)

  const PLUS_SIGN_OVERRIDES = {
    frameProductPage: <AiOutlinePlus pid={2} />,
  }
  const MINUS_SIGN_OVERRIDES = {
    frameProductPage: <AiOutlineMinus />,
  }
  const PlusImage = PLUS_SIGN_OVERRIDES[page] || (
    <GatsbyImage image={plusImg.childImageSharp.gatsbyImageData} alt="Plus Sign" />
  )
  const MinusImage = MINUS_SIGN_OVERRIDES[page] || (
    <GatsbyImage image={minusImg.childImageSharp.gatsbyImageData} alt="Minus Sign" />
  )

  return (
    <div>
      {showAnswer ? (
        <div>
          <AnswerFirstRow
            role="button"
            onClick={() => setShowAnswer(false)}
            onKeyDown={() => setShowAnswer(false)}
            tabIndex={0}
          >
            <Question>{question}</Question>
            <ImageContainer>{MinusImage}</ImageContainer>
          </AnswerFirstRow>
          <Answer display="block">
            {answer.split('\n').map((a, i) => (
              <>
                {a ===
                  'Absolutely. You can cancel anytime right from our website or by reaching out to our customer service team.' &&
                !IS_FOREIGN_COUNTRY ? (
                  <span key={`x${i}`}>
                    Absolutely! If you purchased your subscription through Apple Pay or Google Play,
                    please proceed to cancel your subscription on the platform where you made the
                    purchase. <br />
                    <br />
                    For subscriptions purchased through our website, you can choose from two
                    cancellation methods: either by contacting our customer service team
                    support@skylightframe.com or by managing your subscription directly
                    <ALink
                      href="https://manage.skylightframe.com/account/login?return_url=%2Faccount"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b> here</b>
                    </ALink>
                    . If you need guidance on setting up a login for the Plus account management
                    page, or if you need to update your billing information, you can find relevant
                    details
                    <ALink
                      href="https://skylight.zendesk.com/hc/en-us/articles/12191842983323-How-do-I-update-my-billing-information-for-my-Plus-Subscription-"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b> here</b>
                    </ALink>
                    . It's important to remember that the login credentials for your Plus account
                    are distinct from those you use for app.ourskylight.com.
                  </span>
                ) : (
                  <span key={`x${i}`}>
                    {a} {` `}
                    {(placement === i || !placement) && (
                      <>
                        <ALink href={link} target="_blank" rel="noopener noreferrer">
                          <b>{text}</b>
                        </ALink>
                        {punctuation}
                      </>
                    )}
                  </span>
                )}
              </>
            ))}
          </Answer>
          <Border />
        </div>
      ) : (
        <div>
          <QuestionRow
            role="button"
            onClick={() => setShowAnswer(true)}
            onKeyDown={() => setShowAnswer(true)}
            tabIndex={0}
          >
            <Question>{question}</Question>
            <ImageContainer>{PlusImage}</ImageContainer>
          </QuestionRow>
          <Border />
        </div>
      )}
    </div>
  )
}

FAQItems.propTypes = {
  question: PropTypes.any,
  answer: PropTypes.any,
  page: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  punctuation: PropTypes.string,
  placement: PropTypes.number,
}
export default FAQItems
