import React from 'react'
import PropTypes from 'prop-types'
import FAQSection from '../FAQSection'

import { FAQSectionContainer } from './styles'

const FAQs = ({ page, cta }) => (
  <FAQSectionContainer>
    <FAQSection page={page} cta={cta} />
  </FAQSectionContainer>
)
FAQs.propTypes = {
  page: PropTypes.string,
  cta: PropTypes.string,
}
export default FAQs
