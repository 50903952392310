export const CAL_FAQ_QUESTIONS = [
  {
    sectionTitle: `Getting Started`,
    content: [
      {
        q: `How do I activate my Skylight Calendar?`,
        a: `To activate your Skylight Calendar, visit app.ourskylight.com/register or download our mobile app. There, you will set up your Skylight account and create your Skylight Calendar's unique email address (e.g. johndoe@ourskylight.com), and receive your 6-digit activation code.`,
      },
      {
        q: `How do I get the Skylight mobile app?`,
        a: `The Skylight mobile app is available for Apple and Android products. You can download it`,
        text: ` here`,
        link: `https://bit.ly/skylightapp.`,
        punctuation: `.`,
      },
    ],
  },
  {
    sectionTitle: `How It Works`,
    content: [
      {
        q: `Can you tell me the differences between the Skylight Calendar and Photo Frame?`,
        a: `The Skylight frame is a touchscreen display that everyone in the family can send photos to from anywhere and they'll pop right up on the frame instantly. It's designed to be the perfect family-friendly digital photo frame! 
        
        The Skylight Calendar syncs all of the events from different calendars that your family uses and shows them all together on one beautiful, touchscreen display. It also hosts organizational tools such as the new Chore Chart feature which displays organized lists of what each person needs to get done, so they can stay on top of household duties! Additional organizational features include: Meal planning, List building of any kind, and weather forecast at the time and location of your event!`,
      },
      {
        q: `Do you need an Internet connection to use Skylight Calendar?`,
        a: `Yes, Skylight Calendar needs a WiFi connection at all times to operate.`,
      },
      {
        q: `Is it possible to get on social media or the internet using this product?`,
        a: `No, there is no way to access social media or the internet using our Skylight products.`,
      },
      {
        q: `Can Skylight Calendar be mounted on a wall?`,
        a: `Yes! All of our Skylight products are capable of being wall mounted! See here for more information.`,
      },
      {
        q: `Does Skylight Calendar run on battery power?`,
        a: `No. Skylight Calendar needs to be plugged in to an electrical outlet to work.`,
      },
      {
        q: `What calendars can I sync with Skylight?`,
        a: `Skylight Calendar currently supports syncing with Google, Apple, Outlook, Yahoo, Cozi, and Readdle Calendars, as well as any calendar with a public sharing link. You can sync calendars using our mobile app or online Skylight Cloud (i.e. app.ourskylight.com).`,
      },
      {
        q: `How do I view and manage synced calendars?`,
        a: `You can view and manage the calendars you have synced with Skylight Calendar using the sync icon in the Skylight mobile app or the 'Synced Calendars' section in the Skylight Cloud online. Once your calendars are synced, you can delete them at any time.`,
      },
      {
        q: `How do I share events to my Skylight?`,
        a: `Add your Skylight Calendar's unique email address as an attendee to any event, or sync your entire calendar and as many others as you want with your Skylight Calendar.`,
      },
      {
        q: `Can you add an event to multiple Skylights at once?`,
        a: `Yes, you can invite multiple Skylight's to the event you want them all to have or sync the same calendar to both Skylight's and they will both update in real time as you update that calendar!`,
      },
      {
        q: `What other features does Skylight Cal have?`,
        a: `Skylight Calendar's touchscreen allows you to interact with your events, add/remove events directly onto the device, create Todo/Chore items, view weather forecast at the time and location of your event, update your schedule with a Daily Meal Plan, and view your schedule in Day, Week, and Month views. You can also color code each event on your calendar, as well as create any kind of lists you choose using the 'Lists' feature in your Skylight mobile app and directly on the device.`,
      },
      {
        q: `Does Skylight support chore charts & lists for multiple people?`,
        a: `Yes, with Skylight Calendar you can create chores for multiple people and have as many different kinds of lists as you'd like! A grocery list will be created by default.`,
      },
      {
        q: `What kind of return policy do you provide?`,
        a: `Enjoy peace of mind with a 100% Satisfaction Guarantee. If you don't love your Skylight Calendar, we will offer you a full refund.`,
      },
    ],
  },
]
