export const UNIFIED_FAQ_QUESTIONS = [
  {
    sectionTitle: `FAQs`,
    content: [
      {
        q: `How do I get the Skylight mobile app?`,
        a: `The Skylight mobile app is available for Apple and Android products. You can download it`,
        text: ` here`,
        link: `https://bit.ly/skylightapp.`,
        punctuation: `.`,
      },
      {
        q: `Do you need an Internet connection to use Skylight products?`,
        a: `Skylight Frame only requires a WiFi connection to receive new photos and access new features. Existing photos will appear with or without a WiFi connection. It is recommended that Skylight Calendar be consistently connected to WiFi to ensure all events are reflected and up to date.`,
      },
      {
        q: `Do Skylight products run on battery power?`,
        a: `No. Skylight products need to be plugged in to an electrical outlet to work.`,
      },
      {
        q: `What is Skylight's refund and replacement policy?`,
        a: `Enjoy peace of mind with a 100% Satisfaction Guarantee. If you don't love your Skylight Products, we will offer you a full refund.`,
      },
      {
        q: `How do I get help or support?`,
        a: `Please always feel free to reach out to our support team via email at help@skylightframe.com or view our support center`,
        text: ` here`,
        link: `https://skylight.zendesk.com/hc/en-us`,
        punctuation: `.`,
      },
    ],
  },
]
